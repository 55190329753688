import React from "react";
import hexToRGBA from "../../helper/hexToRbga";
import ActionsDots from "../../assets/images/3dots.svg";
import { Popover } from "antd";
const Table = ({ columns, data, headColor, actionsBtn }) => {
  const content = () => {
    return (
      <div className="flex flex-col ">
        <span className="cursor-pointer m-1 ">View</span>
        <span className="cursor-pointer m-1 ">Delete</span>
      </div>
    );
  };
  return (
    <table className=" table-auto w-full">
      <thead>
        <tr
          style={{
            backgroundColor: hexToRGBA(headColor, 0.1) || "#f0f0f0",
            borderColor: hexToRGBA(headColor, 0.2),
            borderWidth: "0 1px",
          }}
        >
          {columns.map((column) => (
            <th key={column.key} className="p-3 text-sm font-semibold">
              {column.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody
        style={{
          borderColor: hexToRGBA(headColor, 0.2),
          borderWidth: "0 1px 1px",
        }}
      >
        {data?.map((row, i) => (
          <tr key={i}>
            {columns.map((column, id) => {
              if (column.key === "actions") {
                return (
                  <td key={id} className="p-3 text-sm w-1/6 ">
                    <Popover
                      placement="bottom"
                      trigger="click"
                      content={() => {
                        return (
                          <div className="flex flex-col ">
                            <span
                              className="cursor-pointer m-1 "
                              onClick={() => actionsBtn(row._id, "view")}
                            >
                              View
                            </span>

                            <span
                              className="cursor-pointer m-1 "
                              onClick={() => actionsBtn(row._id, "edit")}
                            >
                              Edit
                            </span>
                            <span
                              className="cursor-pointer m-1 "
                              onClick={() => actionsBtn(row._id, "delete")}
                            >
                              Delete
                            </span>
                          </div>
                        );
                      }}
                    >
                      <img src={ActionsDots} alt="actions" />
                    </Popover>
                  </td>
                );
              } else {
                return (
                  <td key={id} className="p-3 text-sm">
                    {row[column.key]}
                  </td>
                );
              }
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;

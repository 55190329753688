import React, { useEffect, useState } from "react";
import Button from "../../components/Button/button.component";
import { ConfigProvider, Form, Input, Select, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { addLocations } from "../../redux/owner/owner.action";
import AutoCompleteMaps from "../../components/map/autocomplete";
import GoogleMaps from "../../components/map";
import { CloseOutlined } from "@ant-design/icons";
import { getListTimezone } from "../../redux/location/location.action";
const AddLocations = ({
  nextStep,
  colorTheme,
  isAddLocation,
  setIsAddLocation,
  withoutSkipButton,
}) => {
  const dispatch = useDispatch();

  const { timezone } = useSelector((state) => state.location);
  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { addLocationStatus } = useSelector((state) => state.owner);
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const [cookies] = useCookies(["user"]);

  const [formattedAddress, setFormattedAddress] = useState(null);
  const [latLong, setLatLong] = useState({
    lat: 56.951474436830196,
    lng: 24.11326717154613,
  });
  const [payload, setPayload] = useState([]);

  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies?.user.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies?.user?.studio_id;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getListTimezone(user_token, studio_id));
  }, [studio_id, user_token, dispatch]);

  useEffect(() => {
    if (addLocationStatus === "success" && isAddLocation) {
      setIsAddLocation(false);
    } else if (addLocationStatus === "success") {
      nextStep();
    }
  }, [addLocationStatus, isAddLocation]);

  const handleAdd = (values) => {
    setPayload([
      ...payload,
      {
        name: values.location_name,
        timezone: values.time_zone,
        address: formattedAddress ?? "Jakarta, indonesia",
        latitude: latLong.lat,
        longitude: latLong.lng,
      },
    ]);
    setFormattedAddress(null);
  };

  const handleRemoveLocation = (idx) => {
    setPayload(payload.filter((_, index) => index !== idx));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleSubmit = () => {
    const body = {
      locations: payload,
    };
    try {
      dispatch(addLocations(body, user_token, studio_id));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-full p-4 min-h-screen flex flex-col items-center">
      {isAddLocation ? null : (
        <h2 className="font-semibold my-10">Add your studio locations</h2>
      )}
      <div className=" text-black text-[20px] font-semibold my-6">
        Add Locations
      </div>

      <ConfigProvider
        theme={{
          components: {
            Form: {},
          },
        }}
      >
        <Form
          onFinish={handleAdd}
          layout="vertical"
          className="w-full md:w-[568px]"
          onKeyDown={handleKeyDown}
        >
          <Form.Item label="Location Name" name="location_name">
            <Input className="h-10" placeholder="Input Your Location Name" />
          </Form.Item>
          <Form.Item label="Time Zone" name="time_zone">
            <Select
              showSearch
              options={timezone?.map((item) => {
                return { value: item, label: item };
              })}
              placeholder="Select Your Time Zone"
              className="h-10"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
            />
          </Form.Item>
          <AutoCompleteMaps
            formattedAddress={formattedAddress}
            setFormattedAddress={setFormattedAddress}
            setLatLong={setLatLong}
            required={false}
            className="my-8"
          />

          <div className="mt-2 mb-6 ">
            <GoogleMaps latLong={latLong} />
          </div>

          {payload.length > 0 ? (
            <div className="my-6">
              <b>Location Choosen</b>
              {payload.map(({ name, address }, idx) => (
                <Row justify="space-between" className="my-6">
                  <Col>
                    <b style={{ color: colorTheme }}>{name}</b>
                  </Col>
                  <Col>
                    <b>{address?.split(",")[0]}</b>
                  </Col>
                  <Col>
                    <CloseOutlined onClick={() => handleRemoveLocation(idx)} />
                  </Col>
                </Row>
              ))}
            </div>
          ) : (
            <></>
          )}

          <div className="flex justify-center mb-6">
            <Button
              buttonType="submit"
              type="outline-secondary"
              title="Add"
              style={{
                border: `1px solid ${colorTheme}`,
                color: colorTheme,
                borderRadius: 0,
              }}
            />
          </div>

          <Form.Item>
            <Button
              buttonType="button"
              type="primary"
              title="CONTINUE"
              style={{ width: "100%", backgroundColor: colorTheme }}
              isLoading={payload.length === 0}
              onClick={handleSubmit}
            />

            <Button
              buttonType="button"
              type="secondary"
              title={withoutSkipButton ? "Cancel" : "Skip for now"}
              onClick={() => {
                if (withoutSkipButton) {
                  setIsAddLocation(false);
                } else {
                  nextStep();
                }
              }}
              style={{
                width: "100%",
                backgroundColor: "#fff",
                color: colorTheme,
              }}
            />
          </Form.Item>
        </Form>
      </ConfigProvider>
    </div>
  );
};

export default AddLocations;

import React from "react";

import { useSelector } from "react-redux";

import Header from "../Header/header.component";
import Sidebar from "../SidebarOwner";

const OrganismLayout = ({
  title,
  children,
  className,
  isBrowseClasses,
  withLogout,
  withoutHeader,
  isEmbed = false,
  colorTheme,
  studioDetail,
}) => {
  const { studio_detail } = useSelector((state) => state.user);

  if (isEmbed) {
    return (
      <div
        className={`${className ?? "p-3"} bg-white rounded-10 shadow-sm `}
        style={{ height: "100%" }}
      >
        {children}
      </div>
    );
  }

  if (isBrowseClasses || withLogout) {
    return (
      <div>
        <Header
          studioDetail={studioDetail}
          title={title}
          isBrowseClasses={isBrowseClasses}
          withLogout={withLogout}
          colorHeader={
            colorTheme || studio_detail?.styles?.header_color || "#E26851"
          }
        />
        <div
          className={`${className ?? "p-3"} bg-white rounded-10 shadow-sm `}
          style={{ height: "100%" }}
        >
          {children}
        </div>
      </div>
    );
  }
  return (
    <Sidebar>
      <div
        className={`${className ?? "p-3"} bg-white  `}
        style={{ height: "100%" }}
      >
        {children}
      </div>
    </Sidebar>
  );
};

export default OrganismLayout;

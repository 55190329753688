import axios from "axios";
import axiosInstance from "../axiosInstance";

export const sendActivities = async (params, token_jwt, studio_id) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token_jwt}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.post("/classes/invitation", params, config);

  return res;
};

export const getSchedules = async (token_jwt, studio_id, id) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token_jwt}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.get(
    `classes/${id}/schedules?per_page=0`,
    config
  );

  return res;
};

export const getEligiblePlans = async (token_jwt, studio_id, id) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token_jwt}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.get(`classes/${id}/plans`, config);

  return res;
};

export const getClassSessionDetail = async (token_jwt, studio_id, id) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token_jwt}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.get(`class-session/${id}`, config);

  return res;
};

export const downloadSession = async (token_jwt, studio_id, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token_jwt}`,
      "Studio-Id": studio_id,
    },
    responseType: "blob",
  };

  const res = await axiosInstance.get(`class-session/${id}?download=1`, config);
  const outputFilename = `class-session-participant.xlsx`;

  const url = URL.createObjectURL(new Blob([res.data]));

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", outputFilename);
  document.body.appendChild(link);
  link.click();
};

export const addSession = async (token_jwt, studio_id, params, id) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token_jwt}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.post(`/class-session/${id}`, params, config);

  return res;
};

export const editSession = async (token_jwt, studio_id, params, id) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token_jwt}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.put(`/class-session/${id}`, params, config);

  return res;
};

export const editSchedules = async (token_jwt, studio_id, params, id) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token_jwt}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.put(`/class/${id}/schedules`, params, config);

  return res;
};

export const deleteSession = async (token_jwt, studio_id, id) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token_jwt}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.delete(`/class-session/${id}`, config);

  return res;
};

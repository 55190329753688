import { CheckOutlined } from "@ant-design/icons";
import { Divider, Radio, Select, Space, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import hexToRGBA from "../../../../helper/hexToRbga";
import Button from "../../../../components/Button/button.component";
import { useDispatch, useSelector } from "react-redux";
import { getLocation } from "../../../../redux/location/location.action";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { bookClass } from "../../../../redux/student/student.action";

const ModalDetailClass = ({
  redirectToLogin,
  prosessEndroll,
  setProsessEndroll,
  selectedClassDetail,
  colorTheme,
  locationId,
  setLocationId,
  confirmMethod,
  activePlanData,
  onChangeRadio,
  paymentMethod,
  fromOwner,
  student_id,
  setIsModalOpenDetailClass,
  setSelectedClassDetail,
  setPaymentMethod,
  setConfirmMethod,
  setModalSuccess,
  paymentGateway,
  bankCode,
  propsPayment,
  setPropsPayment,
  studioLocation,
}) => {
  console.log({ selectedClassDetail });

  const dispatch = useDispatch();
  const history = useHistory();

  const [cookies] = useCookies(["user"]);

  const { location } = useSelector((state) => state.location);

  const { user, studio_detail } = useSelector((state) => state.user);

  const user_token = user?.token || cookies.user?.token;
  const studio_id = user?.studio_id || cookies.user?.studio_id;

  const studio_location = fromOwner ? location : studioLocation;

  useEffect(() => {
    if (user_token && studio_id) {
      dispatch(
        getLocation(
          `?class_id=${selectedClassDetail?.class_id}&limit=0`,
          user_token,
          studio_id
        )
      );
    }
  }, [dispatch, selectedClassDetail?.class_id, studio_id, user_token]);

  useEffect(() => {
    let url =
      paymentGateway === "stripe"
        ? propsPayment?.stripe_checkout_session_url
        : propsPayment?.payment?.gateway_response?.url;

    if (url !== undefined) {
      if (paymentGateway === "stripe") {
        window.location.href = url;
      } else if (paymentGateway === "oy") {
        window.open(url, "_blank");
      }
    }
  }, [
    paymentGateway,
    propsPayment?.payment?.gateway_response?.url,
    propsPayment?.stripe_checkout_session_url,
  ]);

  const handlePayment = async () => {
    const getPaymentDetails = (method) => {
      switch (method) {
        case "cash":
          return { payment_method: "offline", payment_type: "cash" };
        case "card":
          return { payment_method: "online", payment_type: "card" };
        case "payment_link":
          return { payment_method: "online", payment_type: "payment_link" };
        default:
          return {
            payment_method: "active plan",
            payment_type: "active plan",
            plan_order_id: method,
          };
      }
    };

    const paymentDetails = getPaymentDetails(paymentMethod);

    const params = {
      category: "class",
      class_schedule_id: selectedClassDetail?.class_schedule_id,
      ...paymentDetails,
      location_id: locationId.map((x) => x.value).join(","),
    };
    if (fromOwner) params.student_id = student_id;

    if (paymentMethod === "card") params.success_url = window.location.href;

    const { data } = await bookClass(user_token, params, studio_id);
    setPropsPayment(data?.data);
    setModalSuccess(true);
    setIsModalOpenDetailClass(false);
    setProsessEndroll(false);
    setPaymentMethod(null);
    setConfirmMethod(false);
  };

  return (
    <>
      {prosessEndroll && (
        <div className="w-full text-center text-2xl font-semibold">Payment</div>
      )}

      <div className="flex  justify-between p-4 items-center">
        <span className="font-semibold text-[20px]">
          {selectedClassDetail.class_name}
        </span>
        {selectedClassDetail.is_online ? (
          <Tag color="#27AE60">Online</Tag>
        ) : (
          <Tag color="#2D9CDB">Onsite</Tag>
        )}
      </div>

      <div className="p-4 gap-8 text-[#475569]">
        <div className="flex  items-center">
          <label className="font-bold w-[40%]" style={{ color: colorTheme }}>
            Schedule
          </label>
          <span>
            {moment(selectedClassDetail.class_date).format("LL")}{" "}
            {selectedClassDetail.time}
          </span>
        </div>
        <div className="flex  items-center">
          <label className="font-bold w-[40%]" style={{ color: colorTheme }}>
            Teacher
          </label>
          <span className="font-semibold">{selectedClassDetail.teacher}</span>
        </div>
        <div className="flex  items-center">
          <label className="font-bold w-[40%]" style={{ color: colorTheme }}>
            Slots
          </label>
          <span>{selectedClassDetail.slots} Remaining</span>
        </div>
        <div className="flex gap-4">
          <label className="font-bold w-[40%]" style={{ color: colorTheme }}>
            Description
          </label>
          <p className="whitespace-pre-wrap">
            {selectedClassDetail.about_the_class}
          </p>
        </div>
        <div className="flex  items-center">
          <label className="font-bold w-[40%]" style={{ color: colorTheme }}>
            What You’ll Need
          </label>
          <Space>
            <CheckOutlined />
            <span>{selectedClassDetail.notes}</span>
          </Space>
        </div>

        <Divider style={{ borderColor: "#E0E0E0" }} />

        <div className="grid gap-2">
          <label
            htmlFor="location"
            className="text-slate-900 text-sm font-bold"
          >
            Location
          </label>

          <div
            className={`py-2 px-4`}
            style={{
              backgroundColor: hexToRGBA(colorTheme || "#E26851", 0.1),
              border: `1px solid ${hexToRGBA(colorTheme, 0.2)}`,
            }}
          >
            <span className="font-medium">
              Select a location to set as your timezone reference before
              enrolling.
            </span>
          </div>

          <Select
            labelInValue
            size="large"
            mode="multiple"
            placeholder="Jakarta"
            options={studio_location?.map(({ _id, name }) => ({
              value: _id,
              label: name,
            }))}
            value={locationId}
            onChange={(e) => {
              setLocationId(e);
              setSelectedClassDetail((prev) => ({ ...prev, location: e }));
            }}
            className="w-full rounded-sm"
          />
        </div>

        <Divider style={{ borderColor: "#E0E0E0" }} />

        {!confirmMethod ? (
          <Space direction="vertical">
            {user_token && <b>How do you pay for it?</b>}

            {user_token && (
              <Radio.Group onChange={onChangeRadio} value={paymentMethod}>
                <Space direction="vertical">
                  {activePlanData?.length > 0 &&
                    activePlanData.map((el, idx) => (
                      <Radio
                        key={idx}
                        value={el?._id ?? el.plan_transaction_id}
                      >
                        {el?.plan?.plan_name ?? el?.plan_name}
                      </Radio>
                    ))}
                  {selectedClassDetail?.direct_payment_allowed ? (
                    <Radio
                      value={
                        fromOwner
                          ? "cash"
                          : paymentGateway === "stripe"
                          ? "card"
                          : "payment_link"
                      }
                    >
                      Buy this class for
                      <b>
                        {" "}
                        {selectedClassDetail.total_amount}{" "}
                        {selectedClassDetail.currency?.toUpperCase()}
                      </b>
                    </Radio>
                  ) : (
                    <></>
                  )}
                </Space>
              </Radio.Group>
            )}
          </Space>
        ) : (
          <>
            <div className="text-center text-base font-bold text-gray-600">
              {user_token ? (
                <span>
                  This session for
                  {paymentMethod === "cash" ||
                  paymentMethod === "card" ||
                  paymentMethod === "payment_link"
                    ? ` ${
                        selectedClassDetail.total_amount
                      } ${selectedClassDetail.currency?.toUpperCase()}`
                    : ` ${selectedClassDetail.credits} credits`}
                </span>
              ) : (
                <span>Please login to continue</span>
              )}
              {user_token &&
              activePlanData?.length === 0 &&
              !selectedClassDetail?.direct_payment_allowed ? (
                <Button
                  type="primary"
                  title="Buy a plan"
                  onClick={() => {
                    history.push({
                      pathname: "/admin/buy-plan",
                      state: { student_id, fromOwner: true },
                    });
                  }}
                  style={{
                    backgroundColor: colorTheme || "#E26851",
                    borderRadius: 0,
                  }}
                />
              ) : null}
            </div>
          </>
        )}

        {!prosessEndroll && (
          <div className="flex w-full justify-center">
            <Button
              type="primary"
              title="Enroll Now"
              style={{
                backgroundColor: colorTheme || "#E26851",
                borderRadius: 0,
                marginTop: 24,
              }}
              onClick={() => {
                setProsessEndroll(true);
              }}
            />
          </div>
        )}

        {user_token ? (
          <div className="flex flex-col w-full justify-center items-center">
            {activePlanData?.length > 0 ||
            selectedClassDetail?.direct_payment_allowed ? (
              <Button
                isLoading={locationId.length === 0}
                type="primary"
                title={confirmMethod ? "Enroll Now" : "Confirm"}
                style={{
                  backgroundColor: colorTheme || "#E26851",
                  borderRadius: 0,
                  marginTop: 24,
                  width: "200px",
                }}
                onClick={() => {
                  if (confirmMethod) {
                    handlePayment();
                  } else {
                    setConfirmMethod(true);
                  }
                }}
              />
            ) : (
              <></>
            )}

            <Button
              buttonType="button"
              type="secondary"
              title="Cancel"
              onClick={() => {
                setIsModalOpenDetailClass(false);
                setSelectedClassDetail({});
                setProsessEndroll(false);
                setPaymentMethod(null);
                setConfirmMethod(false);
              }}
              style={{
                backgroundColor: "#fff",
                color: colorTheme,
              }}
            />
          </div>
        ) : (
          <div className="flex justify-center">
            <Button
              type="primary"
              title="Login"
              style={{
                backgroundColor: colorTheme || "#E26851",
                borderRadius: 0,
                marginTop: 24,
                width: "200px",
              }}
              onClick={() => redirectToLogin()}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ModalDetailClass;

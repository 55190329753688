import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import ClassNameSetup from "./classname-setup-component";
import ClassDurationSetup from "./classduration-setup-component";
import ClassAddTeacherSetup from "./classaddteacher-setup-component";

import { useCookies } from "react-cookie";
import { setMenuActive } from "../../../../../redux/user/user.action";

import HeaderSection from "../../../../../components/header-section";
import OrganismLayout from "../../../../../components/Layout/organism-layout";
import { getClassDetail } from "../../../../../redux/owner/owner.action";
import { getClassCategories } from "../../../../../redux/studio/studio.action";

export const AddClass = (props) => {
  const dispatch = useDispatch();

  const [cookies, setCookies] = useCookies(["user"]);

  const [stepSchedule, setStepSchedule] = useState(1);
  const [openPopover, setOpenPopover] = useState(false);

  const { user, studio_detail } = useSelector((state) => state.user);

  const isOnboarding = props.location.state?.isOnboarding;

  const isCreating = window.location.pathname.includes("add");

  const user_token = user?.token ?? cookies.user?.token;
  const studio_id = user?.studio_id ?? cookies.user?.studio_id;

  const id = window.location.pathname.split("/")[3];

  useEffect(() => {
    if (id) {
      dispatch(getClassDetail(user_token, studio_id, id));
    }
    dispatch(getClassCategories(user_token, studio_id));
  }, [dispatch, id, studio_id, user_token]);

  const nextStepSchedule = () => {
    setStepSchedule(stepSchedule + 1);
  };

  const prevStepSchedule = () => {
    setStepSchedule(stepSchedule - 1);
  };

  const handleOpenChangePopover = () => {
    setOpenPopover(!openPopover);
  };

  const renderStepsSchedule = () => {
    switch (stepSchedule) {
      case 1:
        return (
          <ClassNameSetup
            isCreating={isCreating}
            isOnboarding={isOnboarding}
            prevStepSchedule={prevStepSchedule}
            nextStepSchedule={nextStepSchedule}
            setStepSchedule={setStepSchedule}
          />
        );
      case 2:
        return (
          <ClassDurationSetup
            isCreating={isCreating}
            prevStepSchedule={prevStepSchedule}
            nextStepSchedule={nextStepSchedule}
          />
        );
      case 3:
        return (
          <ClassAddTeacherSetup
            id={id}
            isCreating={isCreating}
            prevStepSchedule={prevStepSchedule}
            nextStepSchedule={nextStepSchedule}
            setStepSchedule={setStepSchedule}
          />
        );
      default:
    }
  };

  useEffect(() => {
    dispatch(setMenuActive("Classes"));
  }, []);

  return (
    <OrganismLayout>
      <div className="p-5 w-full flex flex-col gap-3">
        <h4 className="font-bold">{isCreating ? "Add" : "Edit"} Class</h4>
        <HeaderSection
          stepSchedule={stepSchedule}
          studio_detail={studio_detail}
        />

        {renderStepsSchedule()}
      </div>
    </OrganismLayout>
  );
};

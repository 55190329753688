import { Divider, Modal, Space, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import checkmark from "../../assets/images/checkmark.svg";
import hourglass from "../../assets/images/hourglass.svg";

import { CheckOutlined } from "@ant-design/icons";
import Button from "../Button/button.component";

import dayjs from "dayjs";
import hexToRGBA from "../../helper/hexToRbga";
import { useHistory } from "react-router-dom";

const ModalSuccessBookingClass = ({
  selectedClassDetail,
  open,
  setOpen,
  colorTheme,
  propsPayment,
  fromOwner,
}) => {
  const history = useHistory();

  const detailClass = useMemo(
    () => [
      {
        label: "Schedule",
        value: `${moment(selectedClassDetail.class_date).format("LL")} ${
          selectedClassDetail.time
        }`,
      },
      { label: "Teacher", value: selectedClassDetail.teacher },
      { label: "Slots", value: `${selectedClassDetail.slots} Remaining` },
      {
        label: "Location",
        value: `${selectedClassDetail.location?.map((x) => x.label)}`,
      },
      { label: "What You'll Need", value: `${selectedClassDetail.notes}` },
    ],
    [selectedClassDetail]
  );

  const expiredDate = propsPayment?.payment?.expired_date;

  // State to store hours, minutes, and seconds
  const [timeRemaining, setTimeRemaining] = useState({
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  // Function to calculate the time difference
  const calculateTimeLeft = () => {
    const now = dayjs();
    const expiration = dayjs(expiredDate);

    if (expiration.isAfter(now)) {
      const diffInSeconds = expiration.diff(now, "second");
      const hours = String(Math.floor(diffInSeconds / 3600)).padStart(2, "0");
      const minutes = String(Math.floor((diffInSeconds % 3600) / 60)).padStart(
        2,
        "0"
      );
      const seconds = String(diffInSeconds % 60).padStart(2, "0");

      setTimeRemaining({
        hours,
        minutes,
        seconds,
      });
    } else {
      // If the time has expired, set everything to '00'
      setTimeRemaining({
        hours: "00",
        minutes: "00",
        seconds: "00",
      });
    }
  };

  useEffect(() => {
    if (propsPayment?.payment?.payment_status === "pending") {
      // Update the countdown every second
      const timer = setInterval(() => {
        calculateTimeLeft();
      }, 1000);

      // Cleanup timer on component unmount
      return () => clearInterval(timer);
    }
  }, [propsPayment?.payment?.payment_status]);

  return (
    <Modal closable={false} open={open} footer={null} width={700}>
      <div className="p-4">
        {propsPayment?.payment?.payment_status === "pending" ? (
          <div className="grid justify-items-center gap-2">
            <img src={hourglass} alt="" />
            <b className="text-2xl">Pending Payment</b>
            <p>
              Please complete the payment before:{" "}
              {propsPayment?.payment?.expired_date}
            </p>
            <div
              className="rounded-lg py-2 px-6"
              style={{ backgroundColor: hexToRGBA("#E2685133", 0.2) }}
            >
              <b className="text-2xl">
                {`${timeRemaining.hours} : ${timeRemaining.minutes} : ${timeRemaining.seconds}`}
              </b>
            </div>
            <Button
              type="primary"
              title="Continue Payment"
              onClick={() =>
                window.open(
                  propsPayment?.payment?.gateway_response?.url ??
                    propsPayment?.stripe_checkout_session_url
                )
              }
              style={{
                width: 170,
                borderRadius: 0,
                backgroundColor: colorTheme,
              }}
            />
          </div>
        ) : (
          <div className="grid justify-items-center gap-2">
            <img src={checkmark} alt="" />
            <b className="text-2xl">Thank you!</b>
            <p>Your Payment Successfull. You have registered for this class.</p>
          </div>
        )}

        <Divider />

        <div className="flex  justify-between items-center mb-8">
          <b className="text-[20px]">{selectedClassDetail.class_name}</b>

          {selectedClassDetail.is_online ? (
            <Tag color="#27AE60">Online</Tag>
          ) : (
            <Tag color="#2D9CDB" style={{ borderRadius: 99 }}>
              Onsite
            </Tag>
          )}
        </div>

        {detailClass.map((el, idx) => (
          <div key={idx} className="flex  items-center mb-2">
            <label className="font-bold w-[40%]" style={{ color: colorTheme }}>
              {el.label}
            </label>
            {idx === 4 ? (
              <Space>
                <CheckOutlined />
                <span>{el.value}</span>
              </Space>
            ) : (
              <span>{el.value}</span>
            )}
          </div>
        ))}

        <Divider />

        <div className="flex justify-center">
          <Button
            type="secondary"
            title="Back to Classes"
            style={{
              color: colorTheme,
              backgroundColor: "white",
              border: `1px solid ${colorTheme}`,
              borderRadius: 8,
            }}
            onClick={() => {
              if (fromOwner) {
                history.push("/class-list");
              }
              setOpen(false);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalSuccessBookingClass;

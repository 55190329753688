import React, { useEffect } from "react";

import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../components/Button/button.component";

import {
  setStatusDefault,
  updateCancellation,
} from "../../redux/studio/studio.action";
import { Checkbox, ConfigProvider, Form, Input } from "antd";

export const Cancellation = ({ nextStep, colorTheme }) => {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["user"]);

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { status_cancellation, isLoading } = useSelector(
    (state) => state.studio
  );
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (status_cancellation === "success") {
      nextStep();
      dispatch(setStatusDefault());
    }
  }, [status_cancellation]);

  const handleSubmit = ({
    cancellation_before_hours,
    cancellation_policy_title,
    cancellation_policy_description,
    roles = ["Owner"],
  }) => {
    let params = {
      cancellation_before_hours,
      cancellation_policy_title,
      cancellation_policy_description,
      roles: roles.join(),
    };

    dispatch(updateCancellation(params, user_token, studio_id));
  };

  return (
    <div className="w-full p-4 min-h-screen flex flex-col items-center">
      <h2 className="font-semibold my-10">Set your cancellation studio</h2>
      <ConfigProvider
        theme={{
          components: {
            Form: {},
          },
        }}
      >
        <Form
          onFinish={handleSubmit}
          layout="vertical"
          className="md:w-[568px] w-full"
        >
          <div className="font-semibold text-xl text-center mb-4">
            Cancellation Period
          </div>
          <Form.Item label="Hours" name="cancellation_before_hours">
            <Input type="number" min={1} placeholder="1" className="h-10" />
          </Form.Item>
          <div className="text-zinc-500 font-normal">
            'The hour(s) in which a student can cancel for a class before the
            class starts'
          </div>
          <div className="font-semibold text-xl text-center mb-4">
            Cancellation Policy
          </div>
          <Form.Item label="Title" name="cancellation_policy_title">
            <Input placeholder="Input Title..." className="h-10" />
          </Form.Item>
          <Form.Item label="Description" name="cancellation_policy_description">
            <Input.TextArea
              placeholder="Input Description..."
              rows={5}
              className="h-10"
            />
          </Form.Item>
          <Form.Item
            name="roles"
            label="Choose Roles That Can Access The Cancellation"
          >
            <Checkbox.Group>
              <Checkbox disabled value="Owner" checked={true}>
                Owner
              </Checkbox>
              <Checkbox value="Teacher">Teachers</Checkbox>
            </Checkbox.Group>
          </Form.Item>

          <Form.Item>
            <Button
              buttonType="submit"
              type="primary"
              title="CONTINUE"
              style={{ width: "100%", backgroundColor: colorTheme }}
            />
            <Button
              buttonType="button"
              type="secondary"
              title="Skip for now"
              onClick={() => {
                nextStep();
              }}
              style={{
                width: "100%",
                backgroundColor: "#fff",
                color: colorTheme,
              }}
            />
          </Form.Item>
        </Form>
      </ConfigProvider>

      {/* <div
        style={{ maxWidth: "32rem" }}
        className="custom-card add-student-form mx-auto"
      >
        <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <p className="fw-bold">Configure Cancellation Period</p>
          <Row>
            <Col>
              <Form.Group style={{ marginBottom: 10 }}>
                <TextInput
                  required
                  autoFocus
                  label="Hours"
                  placeholder="Input Hours..."
                  value={cancellationBeforeHours}
                  onChange={(event) => {
                    setCancellationBeforeHours(event.target.value);
                  }}
                />
              </Form.Group>
            </Col>
            <Col></Col>
          </Row>
          <p className="heading3">
            The hour(s) in which a student can cancel for a class before the
            class starts
          </p>
          <p>
            <b>Cancellation Policy</b>
          </p>
          <Form.Group style={{ marginBottom: 10 }}>
            <TextInput
              required
              label="Title"
              placeholder="Input Title..."
              value={title || ""}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: 10 }}>
            <label htmlFor="desc" className="custom-form-label">
              Description <span className="text-danger">*</span>
            </label>
            <textarea
              required
              className="text-input"
              name="desc"
              id="desc"
              cols="30"
              rows="5"
              placeholder="Input Description..."
              value={description || ""}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: 10 }}>
            <Row>
              <Col>
                <Form.Check
                  required
                  type="checkbox"
                  label="Owner"
                  id="roleowner"
                  defaultChecked={rolesOwner}
                  disabled={true}
                />
              </Col>
              <Col>
                <Form.Check
                  type="checkbox"
                  label="Teacher"
                  id="roleteacher"
                  onChange={(e) => setRolesTeacher(e.target.checked)}
                />
              </Col>
            </Row>
          </Form.Group>
          <Row className="mt-3">
            <Col>
              <Button
                type="secondary"
                title="Skip for Now"
                onClick={() => {
                  nextStep();
                }}
              />
            </Col>
            <Col>

            </Col>
          </Row>
        </Form>
      </div> */}
    </div>
  );
};

import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

import Button from "../../../../components/Button/button.component";

import {
  addTeacher,
  updateTeacher,
  getTeacherDetail,
  setStatusDefault,
  clearAddTeacherRes,
  setStatusDefaultAddTeacher,
} from "../../../../redux/owner/owner.action";
import AtomLoading from "../../../../components/loding-indicator";
import OnboardingStatus from "../../../../components/onboarding-status";
import { ModalLogin } from "../../../StudioLogin/modal";
import {
  activateAccountUser,
  setMenuActive,
} from "../../../../redux/user/user.action";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import { Checkbox, ConfigProvider, Form, Input, Select } from "antd";
import UploadPicture from "../../../../components/uploadPicture";
import hexToRGBA from "../../../../helper/hexToRbga";

const AddTeachers = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [cookies] = useCookies(["user"]);
  const [pictureData, setPictureData] = useState(null);
  const [showModalReactivateTeacher, setShowModalReactivateTeacher] =
    useState(false);

  const {
    teacher,
    teacher_detail,
    addTeacherStatus,
    updateTeacherStatus,
    isLoadingTeacher,
    onboarding_status,
    add_teacher_res,
  } = useSelector((state) => state.owner);
  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { user, loginFromStudio, studio_detail } = useSelector(
    (state) => state.user
  );

  const id = props.location.state?.id;
  const isOnboarding = props.location.state?.isOnboarding;
  const isCreating = window.location.pathname.includes("add") ? true : false;
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;
  const colorTheme = studio_detail?.styles?.header_color;

  const handleActivateAccount = () => {
    try {
      dispatch(
        activateAccountUser(user_token, studio_id, {
          email: teacher_detail?.email,
          role: "teacher",
        })
      );

      dispatch(setStatusDefault());
      history.goBack();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = ({
    first_name,
    last_name,
    email,
    phone_number,
    send_welcome_email,
    gender,
  }) => {
    let params = {
      first_name,
      last_name,
      email,
      send_welcome_email: send_welcome_email ? 1 : 0,
      gender,
    };
    if (phone_number) params.phone_number = phone_number;
    if (pictureData) params.profile_picture = pictureData;

    isCreating
      ? dispatch(addTeacher(params, user_token, studio_id))
      : dispatch(updateTeacher(params, user_token, studio_id, id));
  };

  useEffect(() => {
    if (id) dispatch(getTeacherDetail(user_token, studio_id, id));
    dispatch(setMenuActive("Teachers"));
  }, [id, dispatch, studio_id, user_token]);

  useEffect(() => {
    if (!isCreating) {
      form.setFieldsValue({
        first_name: teacher_detail?.first_name,
        last_name: teacher_detail?.last_name,
        email: teacher_detail?.email,
        phone_number: teacher_detail?.phone_number,
        gender: teacher_detail?.gender,
      });
      if (teacher_detail?.profile_picture) {
        setPictureData(teacher_detail?.profile_picture);
      }
    }
  }, [teacher_detail]);

  useEffect(() => {
    if (add_teacher_res?.is_account_active === 0) {
      setShowModalReactivateTeacher(true);
      return;
    }

    if (addTeacherStatus === "success" || updateTeacherStatus === "success") {
      history.push("/admin/teachers");
      dispatch(clearAddTeacherRes());
    }
    dispatch(setStatusDefault);
    dispatch(setStatusDefaultAddTeacher());
  }, [
    addTeacherStatus,
    updateTeacherStatus,
    add_teacher_res?.is_account_active,
    dispatch,
    history,
  ]);

  return (
    <>
      <OrganismLayout
        maxWidth="32rem"
        title={isCreating ? "Add Teacher" : "Edit Teacher"}
        onBoardingStatus={
          isOnboarding && <OnboardingStatus selectedStep={1} activeStep={2} />
        }
      >
        {isLoadingTeacher ? (
          <AtomLoading />
        ) : (
          <ConfigProvider
            theme={{
              components: {},
            }}
          >
            <h2 className="font-bold mb-3">
              {isCreating ? "Add Teacher" : "Edit Teacher"}
            </h2>
            <Form
              form={form}
              onFinish={handleSubmit}
              layout="vertical"
              className="w-full md:w-[568px]"
              scrollToFirstError
            >
              <Form.Item
                label="First Name"
                name="first_name"
                rules={[
                  { required: true, message: "Please input your First Name" },
                ]}
              >
                <Input className="h-10" placeholder="Input Your First Name" />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="last_name"
                rules={[
                  { required: true, message: "Please input your Last Name" },
                ]}
              >
                <Input placeholder="Input Your Last Name" className="h-10" />
              </Form.Item>
              <Form.Item
                label="Email Address"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your Email Address",
                  },
                ]}
              >
                <Input placeholder="Input Your Email" className="h-10" />
              </Form.Item>
              <Form.Item label="Phone Number" name="phone_number">
                <Input placeholder="Input Your Phone Number" className="h-10" />
              </Form.Item>
              <Form.Item
                label="Gender"
                name="gender"
                rules={[
                  {
                    required: true,
                    message: "Please select your Gender",
                  },
                ]}
                initialValue="male"
              >
                <Select
                  options={[
                    { value: "male", label: "Male" },
                    { value: "female", label: "Female" },
                  ]}
                  placeholder="Select Your Gender"
                  className="h-10"
                />
              </Form.Item>

              <UploadPicture
                themeColor={colorTheme}
                valuePicture={pictureData}
                fileData={(file) => setPictureData(file)}
              />
              {isCreating && (
                <Form.Item valuePropName="checked" name="send_welcome_email">
                  <Checkbox placeholder="Input Your Phone Number">
                    Send welcome email
                  </Checkbox>
                </Form.Item>
              )}
              <Form.Item>
                <div className="flex justify-between">
                  <Button
                    buttonType="button"
                    type="secondary"
                    title="Cancel"
                    onClick={() => {
                      history.goBack();
                    }}
                    style={{
                      width: "25%",
                      backgroundColor: "#fff",
                      color: colorTheme,
                      borderRadius: 0,
                      border: `1px solid ${hexToRGBA(colorTheme, 0.2)}`,
                    }}
                  />
                  <Button
                    buttonType="submit"
                    type="primary"
                    title={"Save"}
                    style={{
                      width: "25%",
                      backgroundColor: colorTheme,
                      borderRadius: 0,
                    }}
                    // isLoading={isLoadingStudent}
                  />
                </div>
              </Form.Item>
            </Form>
          </ConfigProvider>
        )}
      </OrganismLayout>

      <ModalLogin
        show={showModalReactivateTeacher}
        title="The Account Is Currently Being Deactivated"
        label="Continue to reactivate the teacher account"
        email={teacher_detail?.email}
        titleButton1={"Cancel"}
        titleButton2={"REACTIVATE"}
        handleClose={() => setShowModalReactivateTeacher(false)}
        handleSubmit={handleActivateAccount}
      />
    </>
  );
};

export default AddTeachers;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";

import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { ConfigProvider, Table, Modal, Tag, Space, Select } from "antd";
import AtomLoading from "../../../../components/loding-indicator";
import calender from "../../../../assets/images/calender.svg";

import { DateRange } from "react-date-range";
import "./../students.styles.scss";
import {
  getMyActivePlan,
  getPublicClasses,
  getPublicListOfferingClasses,
  getPublicStudioLocation,
} from "../../../../redux/student/student.action";
import "react-calendar/dist/Calendar.css";

import EmptyData from "../../../../components/no-data";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import hexToRGBA from "../../../../helper/hexToRbga";
import Button from "../../../../components/Button/button.component";
import { CaretDownOutlined } from "@ant-design/icons";
import { useCookies } from "react-cookie";
import { getStudioInfo } from "../../../../services/studios";
import * as authService from "../../../../services/auth";
import { GenerateErrorMessage } from "../../../../services/generate-error-message";
import {
  getStudioDetail,
  requestOtp,
  setLoginFromStudio,
  setNewUser,
  setStatusVerifyDefault,
} from "../../../../redux/user/user.action";
import { ModalLogin } from "../../../StudioLogin/modal";
import StudioLoginForm from "../../../StudioLogin/login-form";
import FormOTP from "../../../../components/OTP";
import ModalDetailClass from "./modal-detail-class";
import ModalSuccessBookingClass from "../../../../components/Modal/success-booking-class";
import { sendGetRequest } from "../../../../services/students";
import { getDisbursementSetting } from "../../../../services/payment";
import { capitalize } from "../../../../helper/truncateString";

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const PublicClassList = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const classIds = window.location.search.split("=")[1];

  const currentDate = new Date();
  let last = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);

  const [propsPayment, setPropsPayment] = useState();
  const [isModalOpenDateRange, setIsModalDateOpeRange] = useState(false);
  const [isModalOpenDetailClass, setIsModalOpenDetailClass] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [className, setClassName] = useState(null);
  const [locationId, setLocationId] = useState([]);
  const [locationFilterId, setLocationFilterId] = useState(null);
  const [classCategoryId, setClassCategoryId] = useState(null);
  const [classCategories, setClassCatogories] = useState([]);
  const [studioClasses, setStudioClasses] = useState([]);
  const [studioLocation, setStudioLocation] = useState([]);
  const [selectedClassDetail, setSelectedClassDetail] = useState({});
  const [state, setState] = useState([
    {
      startDate: currentDate,
      endDate: last,
      key: "selection",
    },
  ]);
  const [colorTheme, setColorTheme] = useState("");
  const [email, setEmail] = useState("");
  const [showModalLogin, setShowModalLogin] = useState(false);
  const [showModalOTP, setShowModalOTP] = useState(false);
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [registerWithSocmed, setRegisterWithSocmed] = useState(false);
  const [prosessEndroll, setProsessEndroll] = useState(false);
  const [confirmMethod, setConfirmMethod] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [method, setMethod] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [paymentGateway, setPaymentGateway] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [studio_detail, setstudio_detail] = useState({ id: "" });

  const [cookies] = useCookies();

  const mobileSize = useMemo(() => window.screen.width < 576, []);

  const { public_classes, isLoadingClasses, active_plan } = useSelector(
    (state) => state.student
  );
  const { user, statusVerify } = useSelector((state) => state.user);

  const isSettlement = propsPayment?.payment?.payment_status === "settlement";

  const user_token = user?.token || cookies.user?.token;
  const studio_id = user?.studio_id || cookies.user?.studio_id;
  const subdomain = "vedic-staging";
  // const subdomain = window.location.host.split(".")[0];
  const paramWP = authService.paramsWP();

  const redirectToLogin = () => {
    setShowModalLogin(true);
  };

  // useEffect(() => {
  //   const params = new URLSearchParams();

  //   params.append(
  //     "start_date",
  //     moment(state[0].startDate).format("YYYY-MM-DD")
  //   );
  //   params.append("end_date", moment(state[0].endDate).format("YYYY-MM-DD"));

  //   if (className) {
  //     params.append("class_name", className);
  //   }

  //   if (locationFilterId) {
  //     params.append("location_id", locationFilterId);
  //   }

  //   if (classCategoryId) {
  //     params.append("category_ids", classCategoryId);
  //   }

  //   dispatch(getPublicListOfferingClasses(subdomain, params.toString()));

  //   if (user_token && studio_id) {
  //     dispatch(getMyActivePlan(user_token, studio_id));
  //   }
  // }, [
  //   className,
  //   dispatch,
  //   locationFilterId,
  //   state,
  //   studio_id,
  //   subdomain,
  //   user_token,
  //   isSettlement,
  //   classCategoryId,
  // ]);

  useEffect(() => {
    handleGetStudioInfo();
    handleGetPublicClasses();
  }, []);

  useEffect(() => {
    handleGetLocation();
  }, [selectedClassDetail?.class_id]);

  useEffect(() => {
    if (statusVerify === "success") {
      dispatch(setStatusVerifyDefault());
      setShowModalOTP(true);
    }

    if (
      user_token &&
      studio_id &&
      Object.keys(selectedClassDetail).length > 0
    ) {
      showModalDetailClass();
      setShowModalOTP(false);
      setShowModalLogin(false);
      handleGetDisbursementSetting();
    }

    if (user_token && studio_id) {
      dispatch(getStudioDetail(user_token, studio_id));
    }
  }, [dispatch, history, statusVerify, studio_id, user_token]);

  useEffect(() => {
    if (studio_detail?.id) {
      handleGetClassCategories();
    }
  }, [studio_detail?.id]);

  // useEffect(() => {
  //   if (paramWP?.end_date || paramWP?.start_date) {
  //     setState([
  //       {
  //         startDate: new Date(paramWP?.start_date),
  //         endDate: new Date(paramWP?.end_date),
  //         key: "selection",
  //       },
  //     ]);
  //   }
  //   if (paramWP?.class_name) {
  //     setClassName(paramWP?.class_name);
  //   }
  //   if (paramWP?.category_ids) {
  //     setClassCategoryId(paramWP?.category_ids);
  //   }
  // }, [paramWP]);

  const columns = [
    {
      title: "Class Name",
      dataIndex: "class_name",
      key: "class_name",
      // render: (text, record) => {
      //   return (
      //     <p
      //       className=" cursor-pointer"
      //       onClick={() => {
      //         setSelectedClassDetail(record);
      //         showModalDetailClass(true);
      //       }}
      //     >
      //       {text}
      //     </p>
      //   );
      // },
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Teacher",
      dataIndex: "teacher",
      key: "teacjer",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => {
        return text ? (
          <Tag color="#27AE60">Online</Tag>
        ) : (
          <Tag color="#2D9CDB">Onsite</Tag>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        if (record.user_has_booked_class) {
          return (
            <div
              style={{ color: colorTheme }}
              className="button flex justify-center items-center"
            >
              Register
            </div>
          );
        }
        return (
          <Button
            onClick={() => {
              setSelectedClassDetail(record);
              showModalDetailClass();
              setProsessEndroll(true);
            }}
            type="primary"
            title="Enroll Now"
            style={{
              backgroundColor: colorTheme || "#E26851",
              borderRadius: 0,
            }}
          />
        );
      },
    },
  ];

  const showModalDateRange = () => {
    setIsModalDateOpeRange(true);
  };

  const handleOkDateRange = () => {
    setIsModalDateOpeRange(false);
  };

  const handleCancelDateRange = () => {
    setIsModalDateOpeRange(false);
  };

  const showModalDetailClass = () => {
    setIsModalOpenDetailClass(true);
  };

  const handleOkDetailClass = () => {
    setIsModalOpenDetailClass(false);
  };

  const handleCancelDetailClass = () => {
    setIsModalOpenDetailClass(false);
  };

  const handleGetPublicClasses = async () => {
    try {
      const { data } = await getPublicClasses(subdomain);
      setStudioClasses(data.data);
    } catch (error) {}
  };

  const handleGetLocation = useCallback(async () => {
    try {
      const { data } = await getPublicStudioLocation(
        subdomain,
        selectedClassDetail?.class_id || ""
      );
      setStudioLocation(data.data);
    } catch (error) {}
  }, [selectedClassDetail?.class_id, subdomain]);

  const handleGetStudioInfo = async () => {
    try {
      const res = await getStudioInfo(subdomain);
      setstudio_detail(res.data.data);
      setColorTheme(res.data.data.studio_header_color);
    } catch (error) {}
  };

  const handleGetClassCategories = async () => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };

      const { data } = await sendGetRequest(
        null,
        null,
        `/studios/${studio_detail.id}/categories`,
        config
      );
      setClassCatogories(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetDisbursementSetting = async () => {
    try {
      const { data } = await getDisbursementSetting(user_token, studio_id);
      const { payment_gateway, recipient_bank_code } = data.data;

      setPaymentGateway(payment_gateway);
      setBankCode(recipient_bank_code);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleClick = () => {
    dispatch(requestOtp(email, subdomain));
  };

  const responseSocialAuth = async (provider, response) => {
    try {
      console.log(response, "response");

      const access_token = response?.accessToken;
      setMethod(provider);
      setAccessToken(access_token);
      setEmail(response.profileObj?.email ?? response?.email);

      if (access_token) {
        const result = await authService.loginWithSocmed(
          provider,
          access_token
        );
        if (result.data.data.token) {
          dispatch(setLoginFromStudio());
          dispatch(setNewUser(result.data?.data));
          setShowModalLogin(false);
        }
      }
    } catch (error) {
      if (error.response.data.message === "User not found") {
        setShowModalRegister(true);
        setRegisterWithSocmed(true);
      }
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const handleRegisterWithSocmed = async () => {
    try {
      const result = await authService.registerWithSocmed(method, accessToken);
      console.log(result, "result");
      if (result.data.data.token) {
        dispatch(setLoginFromStudio());
        dispatch(setNewUser(result.data?.data));
        history.push("/dashboard");
      }
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const redirectToRegister = () => {
    registerWithSocmed
      ? handleRegisterWithSocmed()
      : history.push("/register-student");
  };

  const onChangeRadio = (e) => {
    setPaymentMethod(e.target.value);
  };

  const getStatusOrder = async () => {
    try {
      const { data } = await sendGetRequest(
        user_token,
        studio_id,
        `/orders/${propsPayment?._id}`
      );
      const { payment_status } = data?.data?.payment;
      if (payment_status === "settlement") {
        setPropsPayment();
        setSelectedClassDetail({});
      }
      setPropsPayment(data.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (propsPayment?._id) {
      const intervalId = setInterval(() => {
        getStatusOrder();
      }, 60000); // 60 seconds

      // Cleanup the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
  }, [propsPayment?._id]);

  return (
    <OrganismLayout
      studioDetail={studio_detail}
      colorTheme={colorTheme}
      isEmbed={paramWP.embed}
      isBrowseClasses={!user_token}
      title="Class List"
    >
      {isLoadingClasses ? (
        <AtomLoading />
      ) : (
        <div className={`px-[8px] ${!user_token && "xl:px-24"} py-10`}>
          <div className="lg:flex justify-between">
            <h1 className="text-zinc-800 lg:text-[40px] font-bold">
              Class Offerings
            </h1>

            <Space direction={mobileSize ? "vertical" : "horizontal"}>
              <Space onClick={showModalDateRange}>
                <img alt="" src={calender} width={18} />
                <span className=" pointer custom-hover ms-2">
                  {`${moment(state[0].startDate).format(
                    "DD MMM, YYYY"
                  )} - ${moment(state[0].endDate).format("DD MMM, YYYY")}`}
                </span>
                <CaretDownOutlined className="text-[10px]" />
              </Space>

              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                size="large"
                className="w-[200px] shadow-sm custom-select"
                placeholder="Categories"
                suffixIcon={<CaretDownOutlined />}
                value={classCategoryId}
                onSelect={(e) => setClassCategoryId(e)}
                options={
                  classCategories.map((item) => ({
                    label: capitalize(item.name),
                    value: item._id,
                  })) || []
                }
              />

              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                size="large"
                className="w-[200px] shadow-sm custom-select"
                placeholder="Classes"
                suffixIcon={<CaretDownOutlined />}
                value={className}
                onSelect={(e) => setClassName(e)}
                options={
                  studioClasses.map((item) => ({
                    label: item.class_name,
                    value: item.class_name,
                  })) || []
                }
              />

              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                size="large"
                className="w-[200px] shadow-sm custom-select"
                placeholder="Locations"
                suffixIcon={<CaretDownOutlined />}
                value={locationFilterId}
                onSelect={(e) => setLocationFilterId(e)}
                options={
                  studioLocation.map((item) => ({
                    label: item.name,
                    value: item._id,
                  })) || []
                }
              />

              <Button
                type="primary"
                title="Reset Filter"
                style={{ backgroundColor: colorTheme }}
                onClick={() => {
                  setState([
                    {
                      startDate: currentDate,
                      endDate: last,
                      key: "selection",
                    },
                  ]);
                  setClassName(null);
                  setLocationFilterId(null);
                  setClassCategoryId(null);
                }}
              />
            </Space>
          </div>

          <ConfigProvider
            theme={{
              components: {
                Table: {
                  headerBg:
                    hexToRGBA(studio_detail?.styles?.header_color, 0.1) ||
                    "#fafafa",
                  /* here is your component tokens */
                },
              },
            }}
          >
            <div className="gap-6">
              {public_classes?.length > 0 ? (
                public_classes.map((data, idx) => {
                  const dataClass = data?.class_schedules.map((el, i) => {
                    return {
                      key: i,
                      class_name: el.class_name,
                      time: el.class_start_time + " - " + el.class_end_time,
                      teacher: el.teachers.join(),
                      type: el.is_online,
                      ...el,
                    };
                  });
                  return (
                    <div key={idx} className="py-9">
                      <div
                        style={{
                          borderColor: studio_detail?.studio_header_color,
                        }}
                        className="border border-opacity-30"
                      >
                        <div className="px-2 py-4 flex justify-between items-center font-semibold">
                          <div>{days[moment(data?.class_date).day()]}</div>
                          <div>{moment(data?.class_date).format("LL")}</div>
                        </div>
                        <Table
                          size="middle"
                          columns={columns}
                          dataSource={dataClass}
                          scroll={{ x: 900 }}
                        />
                      </div>
                    </div>
                  );
                })
              ) : (
                <EmptyData />
              )}
            </div>
          </ConfigProvider>
        </div>
      )}

      <Modal
        footer={null}
        open={isModalOpenDateRange}
        onOk={handleOkDateRange}
        onCancel={handleCancelDateRange}
      >
        <div className="py-2 text-center">
          <DateRange
            className="shadow-sm mb-3"
            editableDateInputs={true}
            onChange={(item) => setState([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={state}
          />
          <div className="d-flex justify-content-center ">
            <Button
              type="primary"
              title="Close"
              onClick={handleCancelDateRange}
              style={{
                borderRadius: 0,
                backgroundColor: colorTheme,
              }}
            />
          </div>
        </div>
      </Modal>

      <Modal
        open={isModalOpenDetailClass}
        onOk={handleOkDetailClass}
        onCancel={handleCancelDetailClass}
        footer={null}
        width={700}
      >
        <ModalDetailClass
          bankCode={bankCode}
          paymentGateway={paymentGateway}
          studioLocation={studioLocation}
          setModalSuccess={setModalSuccess}
          propsPayment={propsPayment}
          setPropsPayment={setPropsPayment}
          redirectToLogin={redirectToLogin}
          prosessEndroll={prosessEndroll}
          setProsessEndroll={setProsessEndroll}
          selectedClassDetail={selectedClassDetail}
          colorTheme={colorTheme}
          locationId={locationId}
          setLocationId={setLocationId}
          confirmMethod={confirmMethod}
          activePlanData={active_plan}
          onChangeRadio={onChangeRadio}
          paymentMethod={paymentMethod}
          // fromOwner={true}
          // student_id={"67512a3ca51e1927fe061222"}
          setIsModalOpenDetailClass={setIsModalOpenDetailClass}
          setSelectedClassDetail={setSelectedClassDetail}
          setPaymentMethod={setPaymentMethod}
          setConfirmMethod={setConfirmMethod}
        />
      </Modal>

      <Modal
        width={400}
        footer={null}
        open={showModalLogin}
        onCancel={() => {
          showModalLogin ? setShowModalLogin(false) : setShowModalOTP(false);
        }}
      >
        {showModalOTP ? (
          <FormOTP subdomain={subdomain} />
        ) : (
          <StudioLoginForm
            colorTheme={colorTheme}
            email={email}
            handleClick={handleClick}
            handleChange={handleChange}
            responseSocialAuth={responseSocialAuth}
            hideBrowseClasses
          />
        )}
      </Modal>

      <ModalLogin
        colorTheme={colorTheme}
        show={showModalRegister}
        title="Email Not Registered"
        label="Continue register with"
        email={email}
        titleButton1={"CHANGE"}
        titleButton2={"CONFIRM"}
        handleClose={() => setShowModalRegister(false)}
        handleSubmit={redirectToRegister}
      />

      {/* <ModalLogin
        show={showModalReactivateStudent}
        title="Welcome Back!"
        label="Continue to reactivate your account"
        email={email}
        titleButton1={"Cancel"}
        titleButton2={"CONFIRM"}
        handleClose={() => setShowModalReactivateStudent(false)}
        handleSubmit={sendRequestOtpActivate}
      /> */}

      <ModalSuccessBookingClass
        propsPayment={propsPayment}
        open={modalSuccess}
        colorTheme={colorTheme}
        setOpen={setModalSuccess}
        selectedClassDetail={selectedClassDetail}
      />
    </OrganismLayout>
  );
};

export default PublicClassList;

import axiosInstance from "../axiosInstance";

const subdomain = window.location.host.split(".")[0];

const config = {
  headers: {
    "content-type": "application/json",
  },
};

const configSub = {
  headers: {
    "content-type": "application/json",
    subdomain: subdomain,
  },
};

export const loginWithSocmedMain = async (provider, access_token) => {
  const res = await axiosInstance.post(
    `/auth/${provider}/login`,
    {
      oauth_token: access_token,
    },
    config
  );
  return res;
};

export const registerWithSocmedMain = async (provider, access_token) => {
  const res = await axiosInstance.post(
    `/studios/register/${provider}`,
    {
      oauth_token: access_token,
    },
    config
  );
  return res;
};

export const loginWithSocmed = async (provider, access_token) => {
  const res = await axiosInstance.post(
    `/auth/${provider}/login`,
    {
      oauth_token: access_token,
    },
    configSub
  );
  return res;
};

export const registerWithSocmed = async (provider, access_token) => {
  const res = await axiosInstance.post(
    `/studios/auth/${provider}`,
    {
      oauth_token: access_token,
    },
    configSub
  );
  return res;
};

export const getAllActiveStudio = async (token) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axiosInstance.get(`/studios/active?limit=0`, config);

  return res;
};

export const changeRole = async (token, studio_id, params) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.post(`/profiles/roles`, params, config);

  return res;
};

export const paramsWP = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  return params;
};

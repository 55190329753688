import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { useCookies } from "react-cookie";
import { Link, useHistory } from "react-router-dom";
import { Col, Modal, Row } from "react-bootstrap";
import Button from "../../../../../components/Button/button.component";
import AtomNumberFormat from "../../../../../components/number-format";
import AtomLoading from "../../../../../components/loding-indicator";

import {
  deactivePlan,
  deletePlan,
  getAllPlans,
  setStatusDefault,
} from "../../../../../redux/owner/owner.action";
import EmptyData from "../../../../../components/no-data";
import { useOutsideOnclick } from "../../../../../services/clickOutside";
import OrganismLayout from "../../../../../components/Layout/organism-layout";
import { Table, Tag, Dropdown, Menu, ConfigProvider } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import hexToRGBA from "../../../../../helper/hexToRbga";

const SettingPlans = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);

  const [cookies, setCookies] = useCookies(["user"]);
  const [planType, setPlanType] = useState("membership");
  const [active, setActive] = useState(1);
  // const [index, setIndex] = useState(0);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);

  const class_pass = props.location.state?.class_pass;
  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { isLoading, plans, deactive_status } = useSelector(
    (state) => state.owner
  );
  const { user, loginFromStudio, studio_detail } = useSelector(
    (state) => state.user
  );

  const user_token =
    loginFromStudio || isRegisterStudent ? user.token : cookies.user.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user.studio_id
      : cookies.user.studio_id;
  const colorTheme = studio_detail?.styles?.header_color;

  const tableHead = [
    "Date Created",
    "Plan Name",
    "Price",
    "Notes",
    "Status",
    "Actions",
  ];

  tableHead.splice(3, 0, planType === "membership" ? "Usage Limit" : "Credits");

  const planTypes = [
    { name: "Membership", value: "membership" },
    { name: "Class Pass", value: "classpass" },
  ];

  const columns = [
    {
      title: "Plan Name",
      dataIndex: "plan_name",
      key: "plan_name",
      render: (text, record) => (
        <Link
          to={{
            pathname: "/admin/detail-plan",
            state: { id: record.id },
          }}
          className="capitalize text-black"
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Duration",
      dataIndex: "duration_type",
      key: "duration_type",
      render: (text, record) =>
        planType === "membership" ? (
          record.time_type === "duration" ? (
            <span>{`${record.valid_for} ${text}`}</span>
          ) : (
            <span>
              {`${moment(record.start_valid_date).format(
                "DD-MMM-YYYY"
              )} - ${moment(record.end_valid_date).format("DD-MMM-YYYY")}`}
            </span>
          )
        ) : (
          <span>{`${record.valid_for} ${text}`}</span>
        ),
    },
    {
      title: planType === "membership" ? "Usage Limit" : "Credits",
      dataIndex: planType === "membership" ? "usage_limit" : "credits",
      key: "usage_limit_or_credits",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text) => <AtomNumberFormat prefix="IDR " value={text} />,
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      render: (text) => text ?? "-",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag
          color={status === "active" ? "#27AE60" : "#EB5757"}
          className="capitalize"
        >
          {status}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "actions",
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="1"
                onClick={() =>
                  history.push({
                    pathname: "/admin/edit-plan",
                    state: { id: record.id },
                  })
                }
              >
                Edit
              </Menu.Item>
              <Menu.Item
                key="2"
                onClick={() =>
                  setShow1({
                    id: record.id,
                    visible: true,
                    name: record.plan_name,
                    plan_type: record.plan_type,
                  })
                }
              >
                Delete
              </Menu.Item>
              <Menu.Item
                key="3"
                onClick={() =>
                  setShow2({
                    id: record.id,
                    value: record.status === "active" ? "inactive" : "active",
                    visible: true,
                    title:
                      record.status === "active" ? "Deactivate" : "Activate",
                    name: record.plan_name,
                    plan_type: record.plan_type,
                  })
                }
              >
                {record.status === "active" ? "Deactivate" : "Activate"}
              </Menu.Item>
            </Menu>
          }
        >
          <MoreOutlined className="text-[25px]" />
        </Dropdown>
      ),
    },
  ];

  useOutsideOnclick(wrapperRef, setShow);

  const handleDeletePlan = (id) => {
    dispatch(deletePlan(user_token, studio_id, id));

    setShow(false);
    setShow1({ visible: false });
    setTimeout(() => {
      dispatch(getAllPlans(user_token, studio_id, planType, active));
    }, 2000);
  };

  const handleDeactivatePlan = (id, value) => {
    dispatch(deactivePlan(user_token, studio_id, id, value));

    setShow(false);
    setShow2({ visible: false });
  };

  useEffect(() => {
    if (class_pass) setPlanType("classpass");
  }, [class_pass]);

  useEffect(() => {
    if (deactive_status === "success") {
      dispatch(getAllPlans(user_token, studio_id, planType, active));
      dispatch(setStatusDefault());
    } else {
      dispatch(getAllPlans(user_token, studio_id, planType, active));
    }
  }, [planType, deactive_status, active, dispatch, studio_id, user_token]);

  return (
    <>
      <OrganismLayout title="Plans" maxWidth="44rem">
        {isLoading ? (
          <AtomLoading />
        ) : (
          <Col>
            <div className="flex flex-row justify-content-between items-center my-3">
              <div
                className="shadow-sm  rounded-3 heading3 d-flex overflow-hidden"
                style={{ border: `1px solid ${colorTheme}` }}
              >
                {planTypes.map((el, idx) => (
                  <div
                    key={idx}
                    onClick={() => {
                      setPlanType(el.value);
                    }}
                    className={`p-[12px] pointer font-semibold`}
                    style={{
                      backgroundColor: planType === el.value ? colorTheme : "",
                      color: planType === el.value ? "white" : "black",
                      border: colorTheme,
                    }}
                  >
                    {el.name}
                  </div>
                ))}
              </div>

              <Button
                onClick={() => history.push("/admin/add-plan")}
                type="primary"
                title="Add Plans"
                style={{ backgroundColor: colorTheme }}
              />
            </div>

            <ConfigProvider
              theme={{
                components: {
                  Table: {
                    headerBg:
                      hexToRGBA(studio_detail?.styles?.header_color, 0.1) ||
                      "#fafafa",
                  },
                },
              }}
            >
              <Table
                size="large"
                columns={columns}
                dataSource={plans.data}
                rowKey="id"
                locale={{ emptyText: <EmptyData /> }}
                bordered
                pagination={false}
                scroll={{ x: true }}
                components={{
                  body: {
                    wrapper: (props) => <tbody ref={wrapperRef} {...props} />,
                  },
                }}
                footer={() => (
                  <tr>
                    <td className="border-0 py-4" colSpan={columns.length}></td>
                  </tr>
                )}
              />
            </ConfigProvider>
          </Col>
        )}
      </OrganismLayout>

      <Modal
        centered
        contentClassName="rounded-lg"
        show={show1?.visible}
        onHide={() => setShow1({ visible: false })}
      >
        <div className="text-center p-3 d-flex flex-column align-items-center">
          <h2 className="heading1 m-0 mb-3">Delete Plan</h2>
          <p>Delete this Plan?</p>
          <div className="b-gray mx-5 rounded mb-3">
            <p className="m-0 py-2 px-4">
              <b className="">{show1?.name}</b>
              <br />
              <span className="heading3">{show1?.plan_type}</span>
            </p>
          </div>
          <span className="mb-3 text-danger fs-12">
            Deleting this data will delete all membership data.
          </span>
          <div className="d-flex justify-content-center gap-3">
            <Button
              title="Cancel"
              type="secondary"
              onClick={() => setShow1({ visible: false })}
              style={{
                width: "100%",
                backgroundColor: "#fff",
                color: colorTheme,
              }}
            />
            <Button
              title="DELETE"
              type="primary"
              onClick={() => handleDeletePlan(show1?.id)}
              style={{ width: "100%", backgroundColor: colorTheme }}
            />
          </div>
        </div>
      </Modal>

      <Modal
        centered
        contentClassName="rounded-lg"
        show={show2?.visible}
        onHide={() => setShow2({ visible: false })}
      >
        <div className="mx-md-5 p-3">
          <div className="text-center">
            <h2 className="heading1 mb-3">{"Deactivate"} Plan</h2>
            <p className="pb-3">
              Please confirm you want to {"Deactivate"} this plan.
            </p>{" "}
            <div className="b-gray justify-content-center rounded-lg mb-5 p-3">
              <Row className="mb-3">
                <Col>
                  <span className="lightaquamarine">Plan Name</span> <br />
                  {show2?.name}
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="lightaquamarine">Plan Type</span> <br />
                  {show2?.plan_type}
                </Col>
              </Row>
            </div>
          </div>
          <div className="d-flex justify-content-center gap-3">
            <Button
              title="Cancel"
              type="secondary"
              onClick={() => setShow2({ visible: false })}
              style={{
                width: "100%",
                backgroundColor: "#fff",
                color: colorTheme,
              }}
            />
            <Button
              title={"Deactivate"}
              type="primary"
              onClick={() => handleDeactivatePlan(show2?.id, show2.value)}
              style={{ width: "100%", backgroundColor: colorTheme }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SettingPlans;

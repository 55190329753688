const LocationTypes = {
  GET_TIMEZONE: "GET_TIMEZONE",
  ADD_LOCATION: "ADD_LOCATION",
  UPDATE_LOCATION: "UPDATE_LOCATION",
  DELETE_LOCATION: "DELETE_LOCATION",
  GET_LOCATION: "GET_LOCATION",
  GET_LOCATION_SUCCESS: "GET_LOCATION_SUCCESS",
  GET_LOCATION_FAILED: "GET_LOCATION_FAILED",
};

export default LocationTypes;

import { Nav } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout } from "../../redux/user/user.action";
import "./header.styles.scss";
import { Space } from "antd";
import logo from "./../../assets/images/gigs-logo.svg";

const Header = ({
  title,
  withLogout,
  isBrowseClasses,
  mainPage,
  colorHeader,
  withoutHeader,
  studioDetail,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies, setCookies, removeCookies] = useCookies(["user"]);

  const { user, studio_detail } = useSelector((state) => state.user);

  const studioLogo = studioDetail?.studio_logo_link ?? studio_detail?.logo_link;
  const studioName = studioDetail?.studio_name ?? studio_detail?.studio_name;

  const handleLogout = () => {
    if (cookies.user) {
      removeCookies("user", {
        path: "/",
        domain: process.env.REACT_APP_HOST,
        // domain: ".swift.local",
      });
      removeCookies("subdomain", {
        path: "/",
        domain: process.env.REACT_APP_HOST,
        // domain: ".swift.local",
      });
    }
    dispatch(logout(user?.token));
    history.push("/login");
  };

  return (
    <div>
      <div>
        <div
          style={{ backgroundColor: colorHeader }}
          className="flex items-center justify-between px-4 py-2"
        >
          <Space>
            <img alt="" src={withoutHeader ? logo : studioLogo} width={64} />
            <h2 className="text-white font-black text-xl ">
              {withoutHeader ? "GiGSCLASSROOM" : studioName}
            </h2>
          </Space>

          {withLogout ? (
            <span
              style={{
                width: 120,
                color: colorHeader,
                fontSize: 16,
                fontWeight: 700,
              }}
              className="bg-white cursor-pointer text-center py-2"
              onClick={() => {
                handleLogout();
              }}
            >
              Log Out
            </span>
          ) : isBrowseClasses ? (
            <Nav.Link
              style={{
                width: 120,
                color: colorHeader,
                fontSize: 16,
                fontWeight: 700,
              }}
              className="bg-white text-center"
              href="/login"
            >
              Login
            </Nav.Link>
          ) : (
            <div style={{ width: "70px" }}></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";

import {
  logout,
  setLoginFromStudio,
  setMenuActive,
  setNewUser,
} from "../../redux/user/user.action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import menuImg from "../../assets/images/bars-solid.svg";
import Bell from "../../assets/images/notifBell.svg";
import { Avatar, ConfigProvider, Input, Menu, Modal, Popover } from "antd";
import ModalEditProfile from "../../containers/Studio/Owner/Profile/ModalEditProfile";
import {
  CloseSubMenu,
  OpenSubMenu,
  chooseStudio,
  setStatusDefault,
} from "../../redux/studio/studio.action";
import { toast } from "react-toastify";
import { GenerateErrorMessage } from "../../services/generate-error-message";
import { changeRole, getAllActiveStudio, paramsWP } from "../../services/auth";
import { getDisbursementSetting } from "../../services/payment";
import { UserOutlined } from "@ant-design/icons";

function Sidebar({ children }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [cookies, setCookie, removeCookies] = useCookies(["user", "subdomain"]);
  const [isOpen, setIsOpen] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState("");
  const { isRegisterStudent } = useSelector((state) => state.studio);
  const [isChangeEmail, setChangeEmail] = useState(false);
  const [pageModal, setPageModal] = useState("editProfile");
  const [studios, setStudios] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState();
  const { studio_detail, user, loginFromStudio, active_menu } = useSelector(
    (state) => state.user
  );
  const { status, subMenuOpen, subdomain, user_studio } = useSelector(
    (state) => state.studio
  );

  const paramWP = paramsWP();

  const user_token = user?.token || cookies.user?.token;
  const studio_id = user?.studio_id || cookies.user?.studio_id;
  const role = user?.role || cookies.user?.role;
  const user_name = user?.name || cookies.user?.name;
  const user_profile_picture =
    user?.profile_picture || cookies.user?.profile_picture;

  useEffect(() => {
    const studioActive = async () => {
      try {
        const res = await getAllActiveStudio(user_token);
        setStudios(res.data.data);
      } catch (error) {
        toast.error(<GenerateErrorMessage error={error} />);
      }
    };

    const handleGetDisbursementSetting = async () => {
      try {
        const { data } = await getDisbursementSetting(user_token, studio_id);
        const { payment_gateway } = data.data;

        setSelectedPayment(payment_gateway);
      } catch (error) {
        console.log(error);
      }
    };

    studioActive();

    if (role === "owner") {
      handleGetDisbursementSetting();
    }
  }, [role, studio_id, user_token]);

  const menuAdmin = useMemo(() => {
    const baseMenu = [
      { name: "Dashboard", href: "/" },
      { name: "Student", href: "/admin/students" },
      { name: "Classes", href: "/admin/classes" },
      { name: "Teachers", href: "/admin/teachers" },
      { name: "Plans", href: "/admin/setting-plans" },
      { name: "Report", href: "/admin/report" },
      {
        name: "Settings",
        href: "/admin/settings",
        subMenu: [
          { name: "Background", link: "/admin/settings/setting-background" },
          {
            name: "Cancellation",
            link: "/admin/settings/setting-cancellation",
          },
          {
            name: "Payment Gateway",
            link: "/admin/settings/setting-payment-gateway",
          },
          { name: "Locations", link: "/admin/settings/setting-locations" },
          {
            name: "General Terms",
            link: "/admin/settings/setting-general-terms",
          },
        ],
      },
    ];

    // Add "Balance" menu if selectedPayment is "stripe"
    if (selectedPayment === "stripe") {
      const balanceMenu = { name: "Balance", href: "/admin/balances" };
      const plansIndex = baseMenu.findIndex((item) => item.name === "Plans");
      if (plansIndex !== -1) {
        baseMenu.splice(plansIndex + 1, 0, balanceMenu);
      }
    }

    return baseMenu;
  }, [selectedPayment]);

  const menuTeacher = [
    { name: "Dashboard", href: "/" },
    { name: "Student", href: "/admin/students" },
    { name: "Classes", href: "/admin/classes" },
    // { name: "Profile", href: "/admin/profile" },
  ];

  const menuStudent = [
    { name: "Dashboard", href: "/" },
    { name: "Plans", href: "/plan-list" },
    { name: "Classes", href: "/class-list" },
    // { name: "Profile", href: "/student-profile" },
  ];

  const handleActive = (params) => {
    dispatch(setMenuActive(params));
  };

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        handleLogout();
      }
      return error;
    }
  );

  const handleLogout = () => {
    if (cookies.user) {
      removeCookies("user", {
        path: "/",
        domain: process.env.REACT_APP_HOST,
        // domain: ".swift.local",
      });
      removeCookies("subdomain", {
        path: "/",
        domain: process.env.REACT_APP_HOST,
        // domain: ".swift.local",
      });
    }

    dispatch(logout(user_token));

    history.push("/admin/login");

    // loginFromStudio
    //   ? history.push("/admin/login")
    //   : (window.location.href =
    //     process.env.REACT_APP_PROTOCOL +
    //     process.env.REACT_APP_HOST +
    //     "/login");
  };

  const showModal = () => {
    setChangeEmail(false);
    setOpenPopover(false);
    setIsModalOpen(true);
    setPageModal("editProfile");
  };

  const handleOk = () => {
    setChangeEmail(false);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setChangeEmail(false);
    setIsModalOpen(false);
  };

  const handleOpenChangePopover = () => {
    setOpenPopover(!openPopover);
  };

  const items = useMemo(
    () => [
      {
        key: "menu1",
        label: "Change studios",
        children: studios.map((el) => ({
          key: `${el.id}_${el.subdomain}`,
          label: el.studio_name,
          children:
            el?.roles.length > 1
              ? el.roles.map((role) => ({
                  key: `${el.id}_${el.subdomain}_${role}`,
                  label: role,
                }))
              : false,
        })),
      },
    ],
    [studios]
  );

  const onClickStudio = (e) => {
    const studio_id = e.key.split("_")[0];
    const subdomain = e.key.split("_")[1];
    if (e.key.split("_")[2]) {
      setRoles(e.key.split("_")[2]);
    }
    dispatch(chooseStudio(user_token, studio_id, subdomain));
  };

  const setRoles = async (e) => {
    try {
      const params = {
        role: e,
      };

      const res = await changeRole(user_token, studio_id, params);
      dispatch(setNewUser(res.data.data));

      removeCookies("user", {
        path: "/",
        domain: process.env.REACT_APP_HOST,
      });
      removeCookies("subdomain", {
        path: "/",
        domain: process.env.REACT_APP_HOST,
      });
      dispatch(setLoginFromStudio());
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  useEffect(() => {
    if (status === "success") {
      setCookie("user", user_studio, {
        path: "/",
        // domain: ".swift.local",
        domain: process.env.REACT_APP_HOST,
      });
      setCookie("subdomain", subdomain, {
        path: "/",
        // domain: ".swift.local",
        domain: process.env.REACT_APP_HOST,
      });

      window.location.href =
        process.env.REACT_APP_PROTOCOL +
        subdomain +
        `.${process.env.REACT_APP_HOST}/${
          user_studio.role === "owner" ? "admin/dashboard" : "dashboard"
        }`;

      dispatch(setStatusDefault());
    }
  }, [dispatch, setCookie, status, subdomain, user_studio]);

  const content = (
    <div className="w-[280px]">
      <div className="py-0">
        {studios.length > 0 && (
          <ConfigProvider
            theme={{
              components: {
                Menu: {
                  itemSelectedColor: "rgba(0, 0, 0, 0.88)",
                  itemBg: "transparent", // Removes background styling
                  itemPaddingBlock: "0px", // Adjusts padding
                  itemPaddingInline: "0px", // Adjusts padding
                  itemMarginBlock: "0px", // Adjusts margin
                  itemMarginInline: "0px", // Adjusts margin
                  // Add other custom properties as needed
                },
              },
            }}
          >
            <Menu
              mode="inline"
              items={items}
              className="font-bold capitalize text-left"
              onClick={onClickStudio}
            />
          </ConfigProvider>
        )}
        <div
          className="cursor-pointer hover:opacity-50 mb-2 font-semibold ml-6"
          onClick={showModal}
        >
          Edit My Profile
        </div>
        <div
          className="cursor-pointer hover:opacity-50  text-[#EB5757] font-semibold ml-6"
          onClick={handleLogout}
        >
          Logout
        </div>
      </div>
    </div>
  );

  const handlerActive = (name, path, parent) => {
    handleActive(name);
    history.push(path);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    //get url pathname
    const path = window.location.pathname;

    setCurrentPath(path);
  }, [window.location.pathname]);

  return (
    <div className=" min-h-screen w-full md:flex  ">
      <div
        style={{ backgroundColor: studio_detail?.styles?.header_color }}
        className={`w-full  text-white p-4 md:w-[301px]`}
      >
        <div className=" flex text-2xl font-bold items-center justify-between ">
          <div className="flex items-center">
            <img
              alt=""
              src={
                studio_detail?.logo_link ??
                user?.studio_logo ??
                cookies.user?.studio_logo
              }
              width={64}
              className=" mr-2"
            />
            <p className="m-0 font-black text-3xl">
              {user?.studio_name ?? cookies.user?.studio_name}
            </p>
          </div>

          <img
            alt=""
            src={menuImg}
            className="w-5 md:hidden"
            onClick={toggleSidebar}
          />
        </div>

        <div className={`${!isOpen && "hidden"} md:block`}>
          {role === "teacher" &&
            menuTeacher.map((el, idx) => (
              <div key={idx} className="m-3">
                <div className="d-flex flex-row align-items-center m-4 cursor-pointer hover:text-[#ffcd00]">
                  <div
                    className={`${
                      el.name === active_menu ? "dot-active" : "dot"
                    } rounded-circle mr-4`}
                  ></div>
                  <div
                    onClick={() => handlerActive(el.name, el.href)}
                    className={`${
                      el.name === active_menu && "active"
                    } font-semibold `}
                  >
                    {el.name}
                  </div>
                </div>
              </div>
            ))}

          {role === "owner" &&
            menuAdmin.map((el, idx) => (
              <div key={idx} className="m-3">
                <div className="d-flex flex-row align-items-center  cursor-pointer hover:text-[#ffcd00]">
                  <div
                    className={`${
                      el.name === active_menu ? "dot-active" : "dot"
                    }
                    ${
                      currentPath.split("/")[2] === el?.name.toLowerCase() &&
                      "dot-active"
                    }
                     rounded-circle mr-4`}
                  ></div>
                  <div
                    onClick={() => {
                      if (el.subMenu) {
                        if (subMenuOpen) {
                          dispatch(CloseSubMenu());
                        } else {
                          dispatch(OpenSubMenu());
                        }
                      } else {
                        handlerActive(el.name, el.href);
                      }
                    }}
                    className={`${el.name === active_menu && "active"} ${
                      currentPath.split("/")[2] === el?.name.toLowerCase() &&
                      "active"
                    } font-semibold text-[#BDBDBD]`}
                  >
                    {el.name}
                  </div>
                </div>

                {el.subMenu &&
                  el?.subMenu.map((e, id) => (
                    <div
                      key={id}
                      onClick={() => handlerActive(e.name, e.link, el.name)}
                      className={`py-1 pl-9 flex items-center cursor-pointer hover:text-[#ffcd00]  my-2 ${
                        subMenuOpen ? "block" : "hidden"
                      }`}
                    >
                      <div
                        className={`${
                          currentPath === e.link ? "dot-active" : "dot"
                        } rounded-circle mr-4`}
                      ></div>
                      <div
                        className={`${
                          currentPath === e.link && "active"
                        } font-semibold text-[#BDBDBD]`}
                      >
                        {e.name}
                      </div>
                    </div>
                  ))}
              </div>
            ))}

          {role === "admin" &&
            menuAdmin.map((el, idx) => (
              <div key={idx} className="m-3">
                <div className="d-flex flex-row align-items-center m-4 cursor-pointer hover:text-[#ffcd00]">
                  <div
                    className={`${
                      el.name === active_menu ? "dot-active" : "dot"
                    } rounded-circle mr-4`}
                  ></div>
                  <div
                    onClick={() => handlerActive(el.name, el.href)}
                    className={`${
                      el.name === active_menu && "active"
                    } font-semibold text-[#BDBDBD]`}
                  >
                    {el.name}
                  </div>
                </div>
              </div>
            ))}

          {role === "student" &&
            menuStudent.map((el, idx) => (
              <div key={idx} className="m-3">
                <div className="d-flex flex-row align-items-center m-4 cursor-pointer hover:text-[#ffcd00]">
                  <div
                    className={`${
                      el.name === active_menu ? "dot-active" : "dot"
                    } rounded-circle mr-4`}
                  ></div>
                  <div
                    onClick={() => handlerActive(el.name, el.href)}
                    className={`${
                      el.name === active_menu && "active"
                    } font-semibold text-[#BDBDBD]`}
                  >
                    {el.name}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className="flex-1 ">
        {!paramWP?.embed && (
          <div
            style={{ height: "83px" }}
            className="w-full bg-slate-100  flex justify-between border-b shadow-gray-200 px-5 py-3"
          >
            <Input placeholder="search" className="w-[230px]" />

            <div className="flex items-center gap-3">
              <img alt="" src={Bell} className="w-[28px]" />
              <Popover
                open={openPopover}
                onOpenChange={handleOpenChangePopover}
                trigger="click"
                placement="bottom"
                content={content}
              >
                <Avatar
                  size={50}
                  shape="circle"
                  icon={<UserOutlined />}
                  src={user_profile_picture}
                  className="cursor-pointer"
                />
                {/* {user_profile_picture ? (
                  <img
                    alt=""
                    src={user_profile_picture}
                    className="w-[50px] cursor-pointer"
                  />
                ) : (
                  <div
                    style={{
                      backgroundColor: studio_detail?.styles?.header_color,
                    }}
                    className={`rounded-full text-white font-medium w-[14px] h-[50px] cursor-pointer text-center`}
                  >
                    {user_name.split(" ")[0]?.charAt(0)}{" "}
                    {user_name.split(" ")[1]?.charAt(0)}
                  </div>
                )} */}
              </Popover>
            </div>
          </div>
        )}

        <div className="p-4">{children}</div>
      </div>

      <Modal
        open={isModalOpen}
        onOk={handleOk}
        footer={null}
        onCancel={handleCancel}
      >
        <ModalEditProfile
          isOpen={isModalOpen}
          themeColor={studio_detail?.styles?.header_color}
          page={pageModal}
          setPage={setPageModal}
          onCancel={handleCancel}
        />
      </Modal>
    </div>
  );
}

export default Sidebar;
